import React, { Component } from 'react';
import Header from "../components/header"
import Footer from "../components/footer"
import solutions_back from "../images/solutions_back.png";
import Roobot2 from "../images/RooBot2.png";
import ReCAPTCHA from "react-google-recaptcha";
import SEO from "./seo";

class Contact extends Component {

    recaptchaRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            message: '',
            showMessage: false,
            isValid: false
        }
    }

    onFirstNameChange(event) {
        this.setState({firstname: event.target.value})
    }

    onLastNameChange(event) {
        this.setState({lastname: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }

    onCaptchaChange(event) {
        this.setState({ isValid: true })
    }

    handleSubmit( event ) {
        event.preventDefault();
        if (this.state.isValid) {
            fetch('https://qxhzxov234.execute-api.us-east-1.amazonaws.com/prod', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json()).then(
                data => {
                    this.setState({ showMessage: true });
                }
            );
            return true
        }
        else {
            return false
        }
    }


    render() {
        let submitbtn = <ReCAPTCHA sitekey="6Lel9_wZAAAAADazdgcada3uqQl4nMIK_TYFmQW8"
                                   onChange={this.onCaptchaChange.bind(this)} style={{ float: 'left'}}></ReCAPTCHA>
        if (this.state.isValid) {
            submitbtn = <button type="submit" className="button-green" style={{ float: 'left'}}>SUBMIT</button>
        }
        return <div>
            <SEO title="Contact us"
                 description="A roobrick is a smart performance coach that can cut training costs, improve transparency, and keep everyone focused on what matters most"
            ></SEO>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-md-12 p-0">
                        <div style={{
                            backgroundImage: `url(${solutions_back})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPositionX: 'center'
                        }}>
                            <div style={{minHeight: 150}}>
                                <Header/>
                            </div>
                            <div style={{height: 120}}>
                            </div>
                            <div>
                                <div className="home-hero-div">
                                    <div className="home-hero-titlelight">Contact</div>
                                </div>
                            </div>
                            <div style={{height: 240}}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{height: 100}}></div>

            { this.state.showMessage
                ? <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ height: 100 }}></div>
                            <div className="form-message" style={{ textAlign: 'center'}}>
                                Thank you for your submission.  Someone from Roobricks will be getting
                                in touch shortly.
                            </div>
                            <div style={{ height: 100 }}></div>
                        </div>
                    </div>
                </div>
                : <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-message" style={{ textAlign: 'center'}}>
                                To find out more about Roobricks, or to schedule a demo, please
                                fill in the form below
                            </div>
                            <div style={{ height: '4em' }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                                <div className="form-group form-rb">
                                    <label htmlFor="input_email">Email</label>
                                    <input type="email" className="form-control" id="input_email"
                                           aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                    <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                                        anyone else.</small>
                                </div>
                                <div className="form-group form-rb">
                                    <label htmlFor="name">First Name</label>
                                    <input type="text" className="form-control" value={this.state.firstname} onChange={this.onFirstNameChange.bind(this)} />
                                </div>
                                <div className="form-group form-rb">
                                    <label htmlFor="name">Last Name</label>
                                    <input type="text" className="form-control" value={this.state.lastname} onChange={this.onLastNameChange.bind(this)} />
                                </div>
                                <div style={{ height: '20px' }}/>
                                <div className="form-group form-rb">
                                    <label htmlFor="input_comments">Tell us a little bit about your situation (optional)</label>
                                    <textarea className="form-control" rows="3" value={this.state.message} onChange={this.onMessageChange.bind(this)} ></textarea>
                                </div>

                                {submitbtn}
                            </form>

                        </div>
                    </div>
                </div>

            }

            <div style={{ height: 100 }}></div>

            <div className="roobot-2">
                <img src={Roobot2}/>
            </div>
            <br clear="all"/>

            <Footer/>

        </div>
    }
}

export default Contact;